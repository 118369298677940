import { initialAbility } from '@/plugins/acl/config'
import { TokensDTO } from '@/services/generated/api.generated.v1'
import store from '@/store'
import variables from '@/variables'
import { Ability, AbilityBuilder } from '@casl/ability'
import { ref, watch } from '@vue/composition-api'
import axios from 'axios'
import jwt_decode from 'jwt-decode'
import { SimpleCrypto } from 'simple-crypto-js'

const CryptoSecretKey = 'DetainEHRSecretKeyThatWillAllowUsToBeSuperSecure'
const Is2fa = ref([])
const tokens = ref({})
const userId = ref([])
const siteId = ref([])
const tokenExpiresOn = ref([])
const storedRefreshToken = ref([])
const roles = ref([])
const rolePackage = ref([])
const userData = ref({})
const qrCode = ref('')
const rolePackageArray = ref([])

const loading = ref(false)
const sessionDTO = ref([])
const userResponse = ref([])
const errorMessages = ref([])

export default function useAuthenticationList() {
  function defineAbilities() {
    const { can, build } = new AbilityBuilder(Ability)
    const EncryptedRolePackage = localStorage.getItem('encryptedRolePackage')

    const SCrypt = new SimpleCrypto(CryptoSecretKey)
    const decryptedRolePackage = SCrypt.decrypt(EncryptedRolePackage)
    console.log(`reached define abilities decryptedRolePackage ${decryptedRolePackage}`)

    switch (decryptedRolePackage.toLowerCase()) {
      case 'global':
        // can read public
        can('read', 'Public')
        can('read', 'all')

        // can read Medical
        can('read', 'Medical')

        // can manage All
        can('manage', 'All')

        // can read Practitioner
        can('read', 'Practitioner')

        // can read Site
        can('read', 'Site')
        can('manage', 'Site')
        can('read', 'Admin')
        can('manage', 'Admin')

        // can read OfflineMAR
        can('read', 'OfflineMAR')
        break

      case 'ach administrator':
        // can read public
        can('read', 'Public')
        can('manage', 'all')

        // can read Medical
        can('read', 'Medical')

        // can read Site
        can('read', 'Site')
        can('manage', 'Site')
        can('read', 'Admin')

        // can read OfflineMAR
        can('read', 'OfflineMAR')
        break

      case 'site administrator':
        // can read public
        can('read', 'Public')
        can('manage', 'all')

        // can read Medical
        can('read', 'Medical')

        // can read Site
        can('read', 'Site')
        can('manage', 'Site')
        can('read', 'Admin')
        break

      case 'nurse':
        // can read public
        can('read', 'Public')

        // can read Medical
        can('read', 'Medical')

        // can read Site
        can('read', 'Site')

        // can read OfflineMAR
        can('read', 'OfflineMAR')
        break

      case 'site staff':
        // can read public
        can('read', 'Public')

        // can read Medical
        can('read', 'Medical')

        // can read OfflineMAR
        can('read', 'OfflineMAR')
        break

      case 'read only':
        // can read public
        can('read', 'Public')

        // can read Medical
        can('read', 'Medical')
        break

      case 'mental health':
        // can read public
        can('read', 'Public')

        // can read Medical
        can('read', 'Medical')

        // can read Site
        can('read', 'Site')

        // can read OfflineMAR
        can('read', 'OfflineMAR')
        break

      case 'offline mar':
        // can read public
        can('read', 'Public')

        // can read Medical
        can('read', 'Medical')

        // can read OfflineMAR
        can('read', 'OfflineMAR')
        break

      case 'dental assistant':
        // can read public
        can('read', 'Public')

        // can read Medical
        can('read', 'Medical')

        // can read Site
        can('read', 'Site')

        // can read OfflineMAR
        can('read', 'OfflineMAR')
        break

      case 'medical records':
        // can read public
        can('read', 'Public')

        // can read Medical
        can('read', 'Medical')

        // can read Site
        can('read', 'Site')
        break

      case 'prescriber':
        // can read public
        can('read', 'Public')

        // can read Medical
        can('read', 'Medical')

        // can read Site
        can('read', 'Site')

        // can read Practitioner
        can('read', 'Practitioner')

        // can read OfflineMAR
        can('read', 'OfflineMAR')
        break

      case 'practitioner':
        // can read public
        can('read', 'Public')

        // can read Medical
        can('read', 'Medical')

        // can read Site
        can('read', 'Site')

        // can read Practitioner
        can('read', 'Practitioner')

        // can read OfflineMAR
        can('read', 'OfflineMAR')
        break

      case 'medical technician':
        // can read public
        can('read', 'Public')

        // can read Medical
        can('read', 'Medical')

        // can read Site
        can('read', 'Site')

        // can read OfflineMAR
        can('read', 'OfflineMAR')
        break

      default:
        // can read public
        can('read', 'Public')
        can('read', 'Medical')
        break
    }

    return build()
  }

  const testRefreshGet = () => {
    console.log('reached')
  }

  const initStore = async () => {
    if (localStorage.getItem('encryptedJWToken')) {
      const SCrypt = new SimpleCrypto(CryptoSecretKey)
      const decryptedJwt = SCrypt.decrypt(localStorage.getItem('encryptedJWToken'))
      const decryptedRefreshToken = SCrypt.decrypt(localStorage.getItem('encryptedRToken'))

      userData.value = SCrypt.decrypt(localStorage.getItem('userData'))
      userId.value = SCrypt.decrypt(localStorage.getItem('encryptedUserId'))
      siteId.value = SCrypt.decrypt(localStorage.getItem('encryptedSiteId'))

      const RefreshDTO = {
        Token: decryptedJwt,
        RefreshToken: decryptedRefreshToken,
      }

      var url = variables.INTERNAL_API + '/api/v1/Auth/refresh'

      await axios
        .post(url, RefreshDTO)
        .then(response => {
          console.log(JSON.stringify(response.data))
          const { token, refreshToken } = response.data

          // JWT
          const decoded = jwt_decode(token)

          userId.value = decoded.sub
          siteId.value = decoded.nameid
          rolePackage.value = decoded.name
          tokenExpiresOn.value = decoded.exp
          roles.value = decoded['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']

          // localStorage.setItem('userId', decoded.sub)
          // localStorage.setItem('siteId', decoded.nameid)

          const encryptedJwt = SCrypt.encrypt(token)
          const encryptedRefreshToken = SCrypt.encrypt(refreshToken)
          const encryptedUserId = SCrypt.encrypt(decoded.sub)
          const encryptedSiteId = SCrypt.encrypt(decoded.nameid)
          const encryptedExpiration = SCrypt.encrypt(decoded.exp)
          const encryptedRolePackage = SCrypt.encrypt(decoded.name)

          // Refresh Token
          storedRefreshToken.value = refreshToken
          localStorage.setItem('RefreshToken', refreshToken)

          localStorage.setItem('encryptedUserId', encryptedUserId)
          localStorage.setItem('encryptedSiteId', encryptedSiteId)
          localStorage.setItem('encryptedExpiration', encryptedExpiration)
          localStorage.setItem('encryptedJWToken', encryptedJwt)
          localStorage.setItem('encryptedRToken', encryptedRefreshToken)
          localStorage.setItem('encryptedRolePackage', encryptedRolePackage)

          // User Data
          let userAbility = initialAbility[0]
          userAbility = defineAbilities()

          // localStorage.setItem('rolePackage', decoded.name)
          // localStorage.setItem('userData', JSON.stringify(user))
          localStorage.setItem('userAbility', JSON.stringify(userAbility.rules))

          loading.value = false
        })
        .catch(error => {
          console.log(error)
        })
    }
  }

  const refreshAuth = async () => {
    if (localStorage.getItem('encryptedJWToken')) {
      const SCrypt = new SimpleCrypto(CryptoSecretKey)
      const decryptedJwt = SCrypt.decrypt(localStorage.getItem('encryptedJWToken'))
      const decryptedRefreshToken = SCrypt.decrypt(localStorage.getItem('encryptedRToken'))
      console.log(`decryptedJwt ${decryptedJwt}`)

      // userData.value = JSON.parse(localStorage.getItem('userData'))
      userData.value = SCrypt.decrypt(localStorage.getItem('userData'))
      userId.value = SCrypt.decrypt(localStorage.getItem('encryptedUserId'))
      siteId.value = SCrypt.decrypt(localStorage.getItem('encryptedSiteId'))

      tokens.value = {
        Token: decryptedJwt,
        RefreshToken: decryptedRefreshToken,
      }

      var url = variables.INTERNAL_API + '/api/v1/Auth/refresh'

      await axios
        .post(url, tokens.value)
        .then(response => {
          const { token, refreshToken } = response.data

          console.log(`token ${token}`)

          // JWT
          const decoded = jwt_decode(token)
          console.log(`decoded ${decoded}`)

          userId.value = decoded.sub
          siteId.value = decoded.nameid
          rolePackage.value = decoded.name
          tokenExpiresOn.value = decoded.exp
          roles.value = decoded['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
          localStorage.setItem('userRoles', JSON.stringify(rolePackage.value.toLowerCase()))
          localStorage.setItem('userId', decoded.sub)
          localStorage.setItem('siteId', decoded.nameid)

          const encryptedJwt = SCrypt.encrypt(token)
          const encryptedRefreshToken = SCrypt.encrypt(refreshToken)
          const encryptedUserId = SCrypt.encrypt(decoded.sub)
          const encryptedSiteId = SCrypt.encrypt(decoded.nameid)
          const encryptedExpiration = SCrypt.encrypt(decoded.exp)
          const encryptedRolePackage = SCrypt.encrypt(decoded.name)

          // Refresh Token
          storedRefreshToken.value = refreshToken
          localStorage.setItem('RefreshToken', refreshToken)

          localStorage.setItem('encryptedUserId', encryptedUserId)
          localStorage.setItem('encryptedSiteId', encryptedSiteId)
          localStorage.setItem('encryptedExpiration', encryptedExpiration)
          localStorage.setItem('encryptedJWToken', encryptedJwt)
          localStorage.setItem('encryptedRToken', encryptedRefreshToken)
          localStorage.setItem('encryptedRolePackage', encryptedRolePackage)

          // User Data
          let userAbility = initialAbility[0]
          userAbility = defineAbilities()

          localStorage.setItem('userAbility', JSON.stringify(userAbility.rules))

          loading.value = false
        })
        .catch(error => {
          console.log(error)
        })
    }
  }

  // setup mfa for account
  const mFASetup = EntryDTO => {
    store
      .dispatch('app-auth/mFASetup', EntryDTO, { root: true })
      .then(response => {
        qrCode.value = response
      })
      .catch(error => {
        // TODO: Next Update - Show notification
        alert(`Oops, Unable to setup mfa due to ${error}!`)
      })
  }

  const revokeMfa = user => {
    store
      .dispatch('app-auth/revokeMfa', user)
      .then(response => {
        alert(`user's mfa access revoked: ${response}`)
      })
      .catch(error => {
        // TODO: Next Update - Show notification
        alert(`Oops, Unable to revoke user access due to ${error}!`)
      })
  }

  const revokeUserAccess = user => {
    store
      .dispatch('app-auth/revokeUserAccess', user)
      .then(response => {
        alert(`user's access revoked: ${response}`)
      })
      .catch(error => {
        // TODO: Next Update - Show notification
        alert(`Oops, Unable to revoke user access due to ${error}!`)
      })
  }

  const mFALogin = async payload => {
    await store
      .dispatch('app-auth/mFALogin', payload)
      .then(response => {
        const { token, refreshToken, user } = response

        // Encryption
        const SCrypt = new SimpleCrypto(CryptoSecretKey)

        // JWT
        const decoded = jwt_decode(token)

        userId.value = decoded.sub
        siteId.value = decoded.nameid
        rolePackage.value = decoded.name
        tokenExpiresOn.value = decoded.exp
        roles.value = decoded['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
        localStorage.setItem('userRoles', JSON.stringify(rolePackage.value.toLowerCase()))
        localStorage.setItem('userId', decoded.sub)
        localStorage.setItem('siteId', decoded.nameid)

        const encryptedJwt = SCrypt.encrypt(token)
        const encryptedRefreshToken = SCrypt.encrypt(refreshToken)
        const encryptedUserId = SCrypt.encrypt(decoded.sub)
        const encryptedSiteId = SCrypt.encrypt(decoded.nameid)
        const encryptedExpiration = SCrypt.encrypt(decoded.exp)
        const encryptedRolePackage = SCrypt.encrypt(decoded.name)

        // Refresh Token
        storedRefreshToken.value = refreshToken
        localStorage.setItem('RefreshToken', refreshToken)

        localStorage.setItem('encryptedUserId', encryptedUserId)
        localStorage.setItem('encryptedSiteId', encryptedSiteId)
        localStorage.setItem('encryptedExpiration', encryptedExpiration)
        localStorage.setItem('encryptedJWToken', encryptedJwt)
        localStorage.setItem('encryptedRToken', encryptedRefreshToken)
        localStorage.setItem('encryptedRolePackage', encryptedRolePackage)

        // User Data
        let userAbility = initialAbility[0]
        userAbility = defineAbilities()

        const userData = SCrypt.encrypt(JSON.stringify(user))
        const { displayName } = userData

        localStorage.setItem('userData', userData)
        localStorage.setItem('displayName', displayName)

        localStorage.setItem('userAbility', JSON.stringify(userAbility.rules))

        loading.value = false
      })
      .catch(error => {
        // TODO: Next Update - Show notification
        alert(`Oops, Unable to login! due to ${error}`)
        console.log(`Haven't figure this one out yet ${error}`)
      })
  }

  // Login
  const postAuthAttempt = async EntryDTO => {
    const payload = ref({
      Email: EntryDTO.Email,
      Password: EntryDTO.Password,
      SiteId: EntryDTO.siteId,
    })
    console.log(payload.value)

    localStorage.setItem('useSiteId', EntryDTO.siteId)

    await store
      .dispatch('app-auth/login', payload.value)
      .then(response => {
        console.log(`reached the api response ${JSON.stringify(response.token)}`)
        const { token, refreshToken, user } = response

        console.log('prior to if statement')
        if (token && token.toLowerCase() === 'otp sent to your email') {
          alert('Please check your email for a one-time passcode link')
          errorMessages.value.push('Please check your email for a one-time passcode link')
        } else if (token.toLowerCase() === 'invalid credentials') {
          alert('Invalid email and / or password')
          errorMessages.value.push('Invalid email and / or password')
        } else {
          // Encryption
          const SCrypt = new SimpleCrypto(CryptoSecretKey)

          // JWT
          const decoded = jwt_decode(token)

          userId.value = decoded.sub
          siteId.value = decoded.nameid
          rolePackage.value = decoded.name
          tokenExpiresOn.value = decoded.exp
          roles.value = decoded['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
          localStorage.setItem('userRoles', JSON.stringify(rolePackage.value.toLowerCase()))
          localStorage.setItem('userId', decoded.sub)
          localStorage.setItem('siteId', decoded.nameid)

          const encryptedJwt = SCrypt.encrypt(token)
          const encryptedRefreshToken = SCrypt.encrypt(refreshToken)
          const encryptedUserId = SCrypt.encrypt(decoded.sub)
          const encryptedSiteId = SCrypt.encrypt(decoded.nameid)
          const encryptedExpiration = SCrypt.encrypt(decoded.exp)
          const encryptedRolePackage = SCrypt.encrypt(decoded.name)

          // Refresh Token
          storedRefreshToken.value = refreshToken
          localStorage.setItem('RefreshToken', refreshToken)

          localStorage.setItem('encryptedUserId', encryptedUserId)
          localStorage.setItem('encryptedSiteId', encryptedSiteId)
          localStorage.setItem('encryptedExpiration', encryptedExpiration)
          localStorage.setItem('encryptedJWToken', encryptedJwt)
          localStorage.setItem('encryptedRToken', encryptedRefreshToken)
          localStorage.setItem('encryptedRolePackage', encryptedRolePackage)

          // User Data
          let userAbility = initialAbility[0]
          userAbility = defineAbilities()

          const userData = SCrypt.encrypt(JSON.stringify(user))
          const { displayName } = userData

          localStorage.setItem('userData', userData)
          localStorage.setItem('displayName', displayName)

          localStorage.setItem('userAbility', JSON.stringify(userAbility.rules))
          
          loading.value = false
          console.log('reached end of postauthattempt')
        }
      })
      .catch(error => {
        alert('Unable to login! Please double check your username and password.')
        console.log(`error upon hitting Login button ${error}`)
      })
  }

  // Register User
  // const registerUser = async newUserDTO => {
  //   try {
  //     const response = await store.dispatch('app-auth/registerUser', newUserDTO)
  //     userResponse.value = response
  //
  //     alert(`Attempt to create new user: ${userResponse.value}!`)
  //
  //     loading.value = false
  //   } catch (error) {
  //     // TODO: Next Update - Show notification
  //     console.error(`Unable to register User: due to ${error}`)
  //
  //     errorMessages.value = error.response.data.error
  //   }
  // }
  //
  // watch([], () => {
  //   registerUser()
  // })
  const registerUser = async newUserDTO => {
    await store
      .dispatch('app-auth/registerUser', newUserDTO)
      .then(response => {
        userResponse.value = response

        alert(`Attempt to create new user: ${userResponse.value}!`)

        loading.value = false
      })
      .catch(error => {
        // TODO: Next Update - Show notification
        console.error(`Oops, Unable to register User! due to ${error}`)

        errorMessages.value = error.response.data.error
      })
  }

  watch([], () => {
    registerUser()
  })

  // Password
  const requestPasswordResetEmail = async email => {
    await store
      .dispatch('app-auth/requestPasswordResetEmail', email)
      .then(response => {
        userResponse.value = response

        alert('Check email for reset token')

        loading.value = false
      })
      .catch(error => {
        // TODO: Next Update - Show notification
        console.error(`Oops, Unable to send Password Reset! due to ${error}`)

        errorMessages.value = error.response.data.error
      })
  }

  const resetPassword = async DTO => {
    await store
      .dispatch('app-auth/resetPassword', DTO)
      .then(response => {
        alert(`Password update status: ${response}`)

        loading.value = false
      })
      .catch(error => {
        alert(`Oops, Unable to Update Password! due to: ${error}`)
        errorMessages.value = error.response.data.error
      })
  }

  const updatePhoneNumber = async newPhoneNUmber => {
    console.log(newPhoneNUmber)
    await store
      .dispatch('app-auth/updatePhoneNumber', newPhoneNUmber)
      .then(response => {
        loading.value = false
        alert(`${response} attempt to update phone number`)
      })
      .catch(error => {
        alert(`Unsuccessful attempt to update phone number due to ${error}`)
      })
  }

  const updatePassword = async DTO => {
    await store
      .dispatch('app-auth/updatePassword', DTO)
      .then(response => {
        alert(`${response} atempt to update password`)

        loading.value = false
      })
      .catch(error => {
        alert(`Oops, Unable to Update Password due to: ${error}`)

        errorMessages.value = error.response.data.error
      })
  }

  const confirmEmail = async EntryDTO => {
    await store
      .dispatch('app-auth/confirmEmail', EntryDTO)
      .then(response => {
        alert(`Email Verification status: ${response}`)

        loading.value = false
      })
      .catch(error => {
        console.error(`Oops, Unable to confirm User Email! due to ${error}`)

        alert(`Email Verification status: unable to confirm email due to ${error}`)
        errorMessages.value = error.response.data.error
      })
  }

  const oTPLogin = async payload => {
    localStorage.setItem('useSiteId', payload.siteId)

    await store
      .dispatch('app-auth/oTPLogin', payload)
      .then(response => {
        //console.log(JSON.stringify(response))
        const { token, refreshToken, user } = response

        if (token.toLowerCase() === 'otp sent to your email') {
          alert('Please check your email for a one-time passcode link')
          errorMessages.value.push('Please check your email for a one-time passcode link')
        } else if (token.toLowerCase() === 'invalid credentials') {
          alert('Invalid email and / or password')
          errorMessages.value.push('Invalid email and / or password')
        } else {
          // Encryption
          const SCrypt = new SimpleCrypto(CryptoSecretKey)

          // JWT
          const decoded = jwt_decode(token)

          userId.value = decoded.sub
          siteId.value = decoded.nameid
          rolePackage.value = decoded.name
          tokenExpiresOn.value = decoded.exp
          roles.value = decoded['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
          localStorage.setItem('userRoles', JSON.stringify(rolePackage.value.toLowerCase()))
          localStorage.setItem('userId', decoded.sub)
          localStorage.setItem('siteId', decoded.nameid)

          const encryptedJwt = SCrypt.encrypt(token)
          const encryptedRefreshToken = SCrypt.encrypt(refreshToken)
          const encryptedUserId = SCrypt.encrypt(decoded.sub)
          const encryptedSiteId = SCrypt.encrypt(decoded.nameid)
          const encryptedExpiration = SCrypt.encrypt(decoded.exp)
          const encryptedRolePackage = SCrypt.encrypt(decoded.name)

          // Refresh Token
          storedRefreshToken.value = refreshToken
          localStorage.setItem('RefreshToken', refreshToken)

          localStorage.setItem('encryptedUserId', encryptedUserId)
          localStorage.setItem('encryptedSiteId', encryptedSiteId)
          localStorage.setItem('encryptedExpiration', encryptedExpiration)
          localStorage.setItem('encryptedJWToken', encryptedJwt)
          localStorage.setItem('encryptedRToken', encryptedRefreshToken)
          localStorage.setItem('encryptedRolePackage', encryptedRolePackage)

          // User Data
          let userAbility = initialAbility[0]
          userAbility = defineAbilities()

          const userData = SCrypt.encrypt(JSON.stringify(user))
          const { displayName } = user

          localStorage.setItem('userData', userData)
          localStorage.setItem('displayName', displayName)

          localStorage.setItem('userAbility', JSON.stringify(userAbility.rules))

          loading.value = false
        }
      })
      .catch(error => {
        // TODO: Next Update - Show notification
        alert('Unable to login! Please double check the pass code and re-enter.')
        console.log(`error upon hitting I Accept ${error}`)
      })
  }

  const triggerInactivityOTP = async () => {
    if (localStorage.getItem('encryptedJWToken')) {
      const SCrypt = new SimpleCrypto(CryptoSecretKey)
      const decryptedJwt = SCrypt.decrypt(localStorage.getItem('encryptedJWToken'))

      const config = {
        headers: {
          Authorization: `Bearer ${decryptedJwt}`,
        },
      }

      var url = variables.INTERNAL_API + '/api/v1/Auth/inactive/user'

      await axios
        .get(url, config)
        .then(() => {
          alert('Please check your email for a new token')
        })
        .catch(error => {
          alert(`Unable to send inactivity refresh due to ${error}`)
        })
    }
  }

  const setUserToActive = async PassCode => {
    if (localStorage.getItem('encryptedJWToken')) {
      const SCrypt = new SimpleCrypto(CryptoSecretKey)
      const decryptedJwt = SCrypt.decrypt(localStorage.getItem('encryptedJWToken'))

      const config = {
        headers: {
          Authorization: `Bearer ${decryptedJwt}`,
        },
      }

      var url = variables.INTERNAL_API + '/api/v1/Auth/active/user/' + PassCode

      await axios
        .get(url, config)
        .then(response => {
          alert(JSON.stringify(response.data))

          if (!response.data) localStorage.clear()
        })
        .catch(error => {
          alert(`Unable to send inactivity refresh due to ${error}`)
        })
    }
  }

  const getRolePackageArray = async () => {
    await store
      .dispatch('app-auth/getRolePackageArray')
      .then(response => {
        rolePackageArray.value = response
        loading.value = false
      })
      .catch(error => {
        alert(`Unsuccessful attempt to job titles due to ${error}`)
      })
  }

  const updateGivenUser = async UserDTO => {
    await store
      .dispatch('app-auth/updateGivenUser', UserDTO)
      .then(response => {
        alert(`Attempt to edit user: ${response}`)
        location.reload()
      })
      .catch(error => {
        // TODO: Next Update - Show notification
        alert(`Oops, Unable to update user due to ${error}!`)
      })
  }

  return {
    updateGivenUser,
    getRolePackageArray,
    oTPLogin,
    revokeMfa,
    revokeUserAccess,
    postAuthAttempt,
    TokensDTO,
    loading,
    errorMessages,
    sessionDTO,
    tokens,

    registerUser,
    userResponse,

    requestPasswordResetEmail,
    resetPassword,
    updatePassword,

    confirmEmail,

    userData,
    mFALogin,
    mFASetup,
    testRefreshGet,
    Is2fa,
    initStore,
    refreshAuth,
    updatePhoneNumber,
    qrCode,
    triggerInactivityOTP,
    setUserToActive,
    rolePackageArray,
  }
}
