import {} from './generated/api.generated.v1'
//import ApiHelper from '../utils/ApiHelper';

interface UserToken {
  accessToken: string
  refreshToken: string
}

export default class AuthService {
  private static userToken: UserToken
  public static readonly USER_INFO_KEY = 'USER_INFO_KEY'
  //private readonly _identityClient: IdentityClient_V1;

  /* constructor() {
    this._identityClient = new IdentityClient_V1(
      new ApiConfig(),
      process.env.VUE_APP_API_V1_URL
    );

  }*/

  /*
  public async Login(
    username: string,
    password: string
  ): Promise<AuthenticationResultDTO> {
    const request = new AuthenticationRequestDTO();
    request.userName = username;
    request.password = password;

    const response: AuthenticationResultDTO = await ApiHelper.ParseDTO<
      AuthenticationResultDTO
    >(() => this._identityClient.login(request));

    if (response.succeeded) {
      AuthService.SaveToken({
        accessToken: <string>response.accessToken,
        refreshToken: <string>response.refreshToken
      });
    }

    return response;
  }
*/
  /* public async RefreshToken(): Promise<AuthenticationResultDTO | null> {
    const currentUser = AuthService.GetUserToken();

    if (currentUser == null || currentUser.refreshToken == null) {
      return null;
    }

    const request = new RefreshTokenDTO();
    request.token = currentUser.refreshToken;

    const response = await this._identityClient.refreshToken(request);

    AuthService.SaveToken({
      accessToken: <string>response.accessToken,
      refreshToken: <string>response.refreshToken
    });

    return response;
  }
  */

  public static SaveToken(userToken: UserToken) {
    this.userToken = userToken
    localStorage.setItem(this.USER_INFO_KEY, JSON.stringify(userToken))
  }

  public static RemoveToken() {
    //localStorage.removeItem(this.USER_INFO_KEY)
  }

  public static GetUserToken(): UserToken | null {
    // const inMemory = this.userToken;

    // if (inMemory) return inMemory;

    const inStorage = localStorage.getItem(this.USER_INFO_KEY)

    if (inStorage) {
      return JSON.parse(inStorage) as UserToken
    }

    return null
  }

  public static GetToken(): string {
    return this.GetUserToken()?.accessToken || ''
  }

  async Logout() {
    const currentToken = AuthService.GetUserToken()

    fetch('/logout', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${currentToken}`
      }
    }).then (response => {
      if (!response.ok) {
        throw new Error('Network error');
      }
      localStorage.clear()
    }).catch(error => {
      console.error(`There has been a problem with your fetch operation: ${error.message}`)
    });

    if (currentToken == null || currentToken.refreshToken == null || currentToken.accessToken == null) return

    AuthService.RemoveToken()
  // async Logout() {
  //   const currentToken = AuthService.GetUserToken()
  //
  //   localStorage.clear()
  //   if (currentToken == null || currentToken.refreshToken == null) return
  //
  //   AuthService.RemoveToken()
    /*
    try {
      const request = new RefreshTokenDTO();
      request.token = currentToken.refreshToken;

      await this._identityClient.revokeToken(request);
    } catch (e) {}
    */
  }
}
